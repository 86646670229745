import currencyFormat from '@/helpers/currencyFormat';
import { getHAGenerateInvoicesTotalAmount } from '@/pages/dashboard/commerce/components/sharedActionUtils';
import { Order } from '@/types/schema';
import { Divider, Paper, Stack, Typography } from '@mui/material';
import { startCase, toLower } from 'lodash-es';
import { useTranslation } from 'react-i18next';

export default function TotalSummary( {
	invoices,
	totalRemaining,
	total,
	forHA,
}: {
	invoices: Order[] | undefined,
	totalRemaining: number,
	total: number,
	forHA?: boolean
} ) {
	const { t } = useTranslation();
	
	if ( !invoices || !invoices?.length ) return null;
	
	return (
		<Paper
			variant='borderless'
			sx={{
				p        : 2,
				mb       : 2,
				bgcolor  : 'background.default',
				maxHeight: 400,
				overflowX: 'hidden',
			}}>
			<Stack spacing={2}>
				{invoices?.map( ( invoice, index ) => {
					const amount = forHA ? getHAGenerateInvoicesTotalAmount( [ invoice ] )
						: invoice.grandTotal - ( invoice.paidTotal || 0 );
					
					return (
						<Stack
							key={index}
							direction='row'
							alignItems='center'
							spacing={1}
							justifyContent='space-between'>
							<Typography color='text.seondary'>
								{startCase( toLower( invoice?.type ) )} #{invoice.metadata?.customNumber || invoice.number}:
							</Typography>
							<Typography fontWeight={500}>
								{currencyFormat( amount )}
							</Typography>
						</Stack>
					);
				} )}
				<Divider/>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'>
					<Typography color='text.warning'>
						{`${t( 'common:remaining' )}:`}
					</Typography>
					<Typography fontWeight='bold'>
						{currencyFormat( totalRemaining )}
					</Typography>
				</Stack>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'>
					<Typography color='text.secondary'>
						{`${t( 'common:total' )}:`}
					</Typography>
					<Typography fontWeight='bold'>
						{currencyFormat( total )}
					</Typography>
				</Stack>
			</Stack>
		</Paper>
	);
}
