import { useCloverLocations } from '@/pages/formSelects/locationSelect';
import { useMenu } from '@/providers/menu';
import { Location } from '@/types/schema';
import { LocationOn as LocationOnIcon } from '@mui/icons-material';
import { MenuItem, MenuList } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useRouter } from 'next/router';
import { useState } from 'react';

export function useLocationSelectMenu( type: 'item' | 'client' | 'order' ) {
	const { showMenu } = useMenu();
	const router = useRouter();
	const [ locations ] = useCloverLocations( false, { options: { limit: 20 } } );
	const [ location, setLocation ] = useState<Location | undefined>( undefined );
	
	return {
		location,
		action:
			!isEmpty( locations ) && {
				name: location
					? location?.name || location?.address?.line1 || ''
					: 'All Locations',
				icon   : <LocationOnIcon/>,
				onClick: ( e ) => showMenu( ( { closeMenu } ) => (
					<MenuList sx={{ p: 0 }}>
						{[ { id: '12', name: 'All Locations', address: null } as any,
							...locations ].map( ( dbLocation, index ) => (
							<MenuItem
								key={index}
								selected={location?.id === dbLocation.id}
								onClick={() => {
									setLocation( dbLocation.name === 'All Locations' ? undefined : dbLocation );
									router.push( {
										pathname: router.asPath.split( '?' )[ 0 ],
										query   : {
											s: encodeURIComponent( btoa(
												JSON.stringify( {
													filters: [ dbLocation === 'All Locations' ? undefined : {
														id   : type === 'item' ? 'locations' : 'location',
														value: {
															id: type === 'item'
																? { $in: [ dbLocation.id ] }
																: { $eq: dbLocation.id },
															temp: { checked: true },
														},
													} ],
												} ),
											),
											),
										},
									}, undefined, { shallow: true } ).then();
									
									closeMenu();
								}}>
								{dbLocation.name || dbLocation?.address?.line1 || ''}
							</MenuItem>
						) )}
					</MenuList>
				), e.currentTarget ),
			},
		
	};
}

export default function useOrdersExtraActions() {
	const { location, action } = useLocationSelectMenu( 'order' );
	return {
		variables: { location },
		actions  : [ action ].filter( Boolean ),
	};
	
}

